import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Typography} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {CustomDialogStatus} from "../../modules/order/OrderTypes";

interface CustomDialogProps {
    closeCustomDialog: () => void;
    customDialog?: CustomDialogStatus;
}
const CustomDialog: React.FC<CustomDialogProps> = ({closeCustomDialog, customDialog}) => {
    return <Dialog
        open={!customDialog ? false : customDialog.show}
        onClose={closeCustomDialog}
        aria-labelledby="customized-dialog-title"
        aria-describedby="customized-dialog-title"
        maxWidth={"xs"}
        fullWidth={true}>
        <DialogTitle id="customized-dialog-title">
            {customDialog && customDialog.title}
        </DialogTitle>
        <DialogContent dividers>
            <Typography gutterBottom>
                {customDialog && customDialog.content}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeCustomDialog} color="primary">
                {customDialog && customDialog.buttonText}
            </Button>
        </DialogActions>
    </Dialog>
}

export default CustomDialog