import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import styles from "./MealMoment.module.css";
import * as React from "react";
import {Profile} from "../../../modules/profile/ProfileTypes";
import {SelectProfileComponent} from "../../profile/SelectProfileComponent";

interface SelectProfileDialogProps {
    openSelectProfile: boolean
    handleClose: () => void
    selectProfile: (profile: Profile) => void;
    patient: Profile,
    guests: Profile[]
}
const SelectProfileDialog: React.FC<SelectProfileDialogProps> = ({openSelectProfile, handleClose, patient, guests, selectProfile}) => {
    const handleSelectProfile = (profile: Profile) => {
        selectProfile(profile);
        handleClose();
    }

    return <Dialog
        open={openSelectProfile}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{"Selecteer profiel"}</DialogTitle>
        <DialogContent id={styles.dialogNoPadding}>
            <SelectProfileComponent
                patient={patient}
                guests={guests}
                onSelectProfile={(profile: any) => handleSelectProfile(profile)}/>

        </DialogContent>
    </Dialog>
}

export default SelectProfileDialog